* {
    margin: 0;
    padding: 0;
    outline: none;
    /*ios闪屏*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: 'Helvetica Neue', 'DroidSans', 'Microsoft Yahei';
    word-break: break-all;
}
   
*,
*::before,
*::after {
    box-sizing: border-box;
    
}

html,
body {
    height: 100%;
    font-size: 20px !important;
    width: 100%;
}

a {
    text-decoration: none;
}

.clear {
    clear: both;
}

.hidden,
.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.app-loading {
    width: 2rem;
    height: 2rem;
    animation: loading .8s linear 0s infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    } 
    100% {
        transform: rotate(360deg);
    }
}
.page-loading{
    position: fixed;
    top:0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page-loading>div{
    width: 3rem;
    height: 3rem; 
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: 0 .25rem .75rem 0 rgba(0,0,0,0.10);
    border-radius: .6rem;
}

.app-mask{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.50);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
}
.container-fluid{
    padding: 0;
}
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12{
    padding: 0;
}

/*导航栏*/
.container-fluid{
    position: relative;
    min-width: 1366px;
}
.row{
    padding: 0 12%;
    margin:0
}
.app-top{
    background-image: linear-gradient(rgba(0,0,0,0.60) 0%, rgba(0,0,0,0.00) 100%);
    height: 4rem;
    text-align: center;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1004;
}
.app-top img{
    height: 1.3rem;
}
.app-top-dark{
    background: #272727;
}
.app-top .col-md-5{ 
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    font-size: 20px;
}
.app-top .col-md-7{ 
    color: #fff; 
    height: 100%;
    font-size: .7rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.app-top span{ 
    cursor: pointer;
    text-align: right;
    padding: 0;
}
.app-top .active{
    color: #1088F0;
    position: relative;
}
.app-top span.active::before{
    content: "";
    position: absolute;
    bottom: -.2rem;
    right: 1rem;
    width: .75rem;
    height: .2rem;
    background-image: linear-gradient( #25BDF9 0%, #1088F0 100%);
    border-radius: .1rem;
} 
.app-top span:nth-child(n+5).active::before{ 
    right: 1rem; 
}

/*页脚*/
.app-footer{
    margin-top: 7rem;
    background: #090F1A;
}
.app-footer-top{
    padding: 1.5rem 0;
    color: #fff;
    width: 100%;
}
.app-footer-contact{
    color: #1088F0;
    font-size: .9rem;
    line-height: 1.5rem;
}
.app-footer-addr{
    font-size: .7rem;
    color: rgba(255, 255, 255 ,.5);
    line-height: 1.5rem;
}
.app-footer-addr>div:first-child{
    margin-bottom: 1.5rem;
}
.app-footer-title{
    color: #fff;
    font-weight: 600;
}
.app-footer-top{
    padding:.75rem 0;
    border-top: .05rem solid #2C3343;
    color: rgba(255,255,255,.5);
    font-size: .6rem;
}
#app_footer a{
    color: #fff;
}
#app_footer a:hover{
    color: #fff;
}
#app_footer a:active{
    color: #fff;
}
#app_footer a:focus{
    color: #fff;
}
.app-a-first{
    margin-right: .5rem;
}
.app-a-first img{
    margin-top: -.2rem;
    margin-right: .2rem;
}
